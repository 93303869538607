// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = $;
window.$ = $;
import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import AOS from "aos";
import Swiper, { Autoplay, Navigation } from "swiper";

window.Swiper = Swiper;
Swiper.use([Autoplay, Navigation]);

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

const flatpickr = require("flatpickr/dist/flatpickr");
require("popper.js/dist/popper.js");
require("@popperjs/core");
window.bootstrap = require("bootstrap/dist/js/bootstrap");
// require("summernote/dist/summernote");
require("@nathanvda/cocoon");
var lastScrollTop = 0;
$(function(){

  if ($('.summernote').length > 0) {
    $('.summernote').summernote({
      tabsize: 2,
      height: 200,
      disableDragAndDrop: true,
      callbacks: {
        onChange: function (contents, $editable) {
            $(this).val(contents);
        }
      },
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['link']],
        ['view', ['fullscreen', 'codeview']],
      ],
    });
  }
});

$(window).on("load", function () {
  var hash = location.hash.replace(/^#/, '');  // ^ means starting, meaning only match the first hash
  if (hash) {
      $('a[data-bs-target="#' + hash + '"]').tab("show");
  } 
  // Change hash for page-reload
  // $('#media-tab').on('shown.bs.tab', function (e) {
  //     window.location.hash = $(e.target).data('bs-target');
  // }) 
  Pace.on("done", function () {

    $(".loading-line").removeClass("show");
    $(".loading").fadeOut("slow");
    $("#sub_nav").addClass("show");
    $("#mainNav").addClass("show");
    $(".logo").addClass("show");
    $(".header").addClass("loaded");
    $(".box-container .box").addClass("show");

    $('.inner-header').addClass('show');
  });
  
  AOS.init();
  // $('.summernote').summernote( { height: 250 } );

  flatpickr(".flatpickr");

  if ($(".testimonial-swiper .swiper-slide").length > 0){
    var swiper = new Swiper(".testimonial-swiper", {
      slidesPerView: 1.25,
      spaceBetween: 30,
      slideToClickedSlide: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        //   clickable: true,
      },
      navigation: {
        nextEl: ".testimonial-next",
        prevEl: ".testimonial-prev",
      },
    });
  }
  
  if($(".events_swiper .swiper-slide").length > 0){
    var events_swiper = new Swiper(".events_swiper", {
      slidesPerView: 4,
      spaceBetween: 30,
      slideToClickedSlide: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        //   clickable: true,
      },
      navigation: {
        nextEl: ".testimonial-next",
        prevEl: ".testimonial-prev",
      },
    });
  }
  // Collapse Navbar
  var navbarCollapse = function () {
    if ($("#mainNav").length > 0 && $("#mainNav").offset().top > 100) {
      $("#sub_nav").addClass("navbar-scrolled");
      $("#mainNav").addClass("navbar-scrolled");
    } else {
      $("#sub_nav").removeClass("navbar-scrolled");
      $("#mainNav").removeClass("navbar-scrolled");
    }


    if ($(window).scrollTop() > 40 && $(window).scrollTop() > lastScrollTop) {
      $("#sub_nav").addClass("hide-navigation");
      $("#mainNav").addClass("hide-navigation");
    } else {
      $("#sub_nav").removeClass("hide-navigation");
      $("#mainNav").removeClass("hide-navigation");
    }
    lastScrollTop = $(window).scrollTop();
  };
  // Collapse now if page is not at top
  navbarCollapse();
  // Collapse the navbar when page is scrolled
  $(window).scroll(navbarCollapse);

});
